<template>
	<transition name="load">
		<img
			src="@/assets/images/loading.gif"
			alt="logo"
			height="100"
			v-show="active"
			style="position: absolute"
		/>
	</transition>
</template>

<script>
export default {
	name: "LoadingIndicator",
	props: {
		active: Boolean,
	},
};
</script>

<style scoped>
.load-enter-from {
	opacity: 0;
	transform: scale(-100px);
}
.load-enter-active {
	transition: all 0.2s ease;
}
.load-leave-to {
	opacity: 0;
	transform: scale(100px);
}
.load-leave-active {
	transition: all 0.2s ease;
}
</style>
