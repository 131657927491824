<template>
  <!-- TODO: Make a prop that highlights a few rows -->
  <div class="table-responsive">
    <table class="table" style="border: none">
      <thead>
        <tr>
          <th
            scope="col"
            v-for="(col, index) in columns"
            :key="index"
            class="theme-background-header"
            style="padding: 20px"
            v-show="containColumn.includes(col)"
          >
            <slot :name="col + '-title'" :col="col">
              {{ col }}
            </slot>
          </th>
          <th
            v-if="functions.length > 0 && rows.length > 0"
            style="padding: 20px"
          >
            Functions
          </th>
        </tr>
      </thead>
      <tbody style="border: none">
        <transition-group
          @before-enter="onBeforeEnter"
          @enter="onEnter"
          @leave="onLeave"
        >
          <tr
            v-for="(row, index) in rows"
            :key="index"
            :class="hover ? 'hover' : ''"
            @click="rowClickEvent(index)"
            :data-index="index"
          >
            <td
              v-for="(col, index) in columns"
              :key="index"
              style="padding: 20px"
              v-show="containColumn.includes(col)"
            >
              <!-- {{ row[col] }} -->
              <slot :name="col" :row="row">
                {{ row[col] }}
              </slot>
            </td>
            <!-- Row Functions  -->
            <div v-if="functions.length > 0" style="padding: 0">
              <td
                v-for="(functs, func_index) in functions"
                :key="func_index"
                style="padding: 20px"
              >
                <button class="btn p-2">{{ functs.text }}</button>
              </td>
            </div>
          </tr>
        </transition-group>
      </tbody>
    </table>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "Table",
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
    rows: Array,
    functions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    isHeader: {
      type: Boolean,
      default: true,
    },
    contain: {
      type: Array,
      default: () => {
        return [];
      },
    },
    innerRow: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rowClickFunction: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    // Convert the row keys into column names
    columns: function columns() {
      if (this.rows.length === 0) {
        return [];
      }
      return Object.keys(this.rows[0]).sort((a, b) => {
        return this.contain.indexOf(a) - this.contain.indexOf(b);
      });
    },
    // Column Filtering
    // limit to the ones in contain array
    containColumn() {
      if (this.rows.length === 0) {
        return this.contain;
      }
      return this.contain.length !== 0
        ? this.contain
        : Object.keys(this.rows[0]);
    },
  },
  methods: {
    // If a function button is clicked
    functionEvent(index) {
      if (this.functions[index].event) {
        return this.functions[index].event;
      } else {
        return null;
      }
    },

    // If a row is clicked
    rowClickEvent(index) {
      // console.log("Clicked Row:", index);
      this.rowClickFunction(index);
    },

    // Animations
    onBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-20px)";
    },

    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        delay: el.dataset.index * 0.1,
        onComplete: done,
      });
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 0.1,
        onComplete: done,
      });
    },
  },
};
</script>

<style scoped>
th {
  border-bottom: 1px lightgray solid;
}

td {
  border: none;
}

.hover:hover {
  background-color: #eee;
  cursor: pointer;
}
.hover:active {
  background-color: #ddd;
}

/* Row animation */

.row-anim-enter-active,
.row-anim-leave-active {
  transition: all 0.7s ease;
}
.row-anim-enter-from,
.row-anim-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
